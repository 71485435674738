<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="promotions"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/promotions/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    
                                    <div class="col-md-6" style="display:inherit;">
                                        <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                v-model="filter_date"
                                                label="Chương trình khuyến mại từ ngày"
                                                prepend-icon=""
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="filter_date"
                                                :active-picker.sync="activePicker"
                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                @change="apply_filter_date"
                                            ></v-date-picker>
                                            </v-menu>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.Banner="{ item }">
                                <img :src="item.Banner" style="width: 100px;" />
                            </template>
                            <template v-slot:item.Start_time_active="{ item }">
                                {{ new Date(item.Start_time_active * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.End_time_active="{ item }">
                                {{ new Date(item.End_time_active * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.Start_time_claim="{ item }">
                                {{ new Date(item.Start_time_claim * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.End_time_claim="{ item }">
                                {{ new Date(item.End_time_claim * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <!-- <router-link :to="`/lives/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link> -->
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

function date_to_str(date) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
}

export default {
    metaInfo: {
        title: 'Chương trình khuyến mại'
    },
    data() {
        return {
            loading: false,
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Hình ảnh", value: 'Banner', sortable: false },
                { text: "Tên chương trình", value: 'Name', sortable: false },
                { text: "Bắt đầu C.T", value: 'Start_time_active', sortable: false },
                { text: "Kết thúc C.T", value: 'End_time_active', sortable: false },
                { text: "Bắt đầu nhận thưởng", value: 'Start_time_claim', sortable: false },
                { text: "Kết thúc nhận thưởng", value: 'End_time_claim', sortable: false },
                { text: "Mã K.M", value: 'Voucher_code', sortable: false },
                { text: "Giá trị K.M", value: 'Sale_of', sortable: false },
                { text: "Giá trị K.M tối đa", value: 'Sale_max', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
            ],

            menu: false,
            activePicker: null,
            filter_date: date_to_str(new Date(new Date() - 30 * 24 * 60 * 60 * 1000))
        };
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    computed: {
        promotions() {
            return this.$store.getters['promotions/get_promotions'];
        },
    },
    components: {
        
    },
    methods: {
        get_promotions() {
            var self    = this;
            self.loading = true;
            const filter_date = this.filter_date || "";
            const filter_date_int = Math.floor(new Date(filter_date).getTime() / 1000);

            var payload = {
                create_from: filter_date_int,
            };

            this.$router.push({
                name: "promotions-index",
                query: { create_from: filter_date_int }
            }).catch(() => { });

            this.$store.dispatch('promotions/get_all', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        confirm_delete(item) {
            var self = this;
            Swal.fire({
                title: `Xoá: ${item.Name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('promotions/delete_promotion', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_promotions();
            }).catch(() => {
                this.loading = false;
            });
        },
        
        apply_filter_date(date) {
            console.log('apply_filter_date', date)
            this.filter_date = date;
            this.get_promotions();
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Livestream", route: "index" }, { title: "Tất cả" } ]);
        this.get_promotions();
    }
};
</script>
